@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300;400;500;600;700&display=swap');

.message-container p {
  margin-top: 7px;
  color: #676767;
  font-size: 14px;
}

.current-message-container {
  width: 100%;
  /* padding-left: 10px; */
}

.current-message-container>div {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.current-message-info {
  text-align: center;
}

.current-message-info h3 {
  margin-bottom: 10px;
}

.current-message-info p {
  margin-bottom: 0;
}

.current-message-picture img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.message-meta {
  font-size: small;
  text-align: right;
}

body {
  background-color: #3498db;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.container {
  margin: 60px auto;
  background: #fff;
  padding: 0;
  border-radius: 7px;
}

.message-container {
  width: 100%;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.settings-tray {
  background: #eee;
  padding: 10px 15px;
  border-radius: 7px;
}

.settings-tray .no-gutters {
  padding: 0;
}

.settings-tray--right {
  float: right;
  margin-left: auto;
}

.settings-tray--right i {
  margin-top: 10px;
  font-size: 25px;
  color: grey;
  padding-right: 10px;
  padding-top: 10px;
  transition: 0.3s;
}

.settings-tray--right i:hover {
  color: #74b9ff;
  cursor: pointer;
}

.message-fabutton {
  background: none;
  padding: 0px;
  border: none;
}

.message-fabutton i {
  margin-top: 0px;
  font-size: 25px;
  color: grey;
  transition: 0.3s;
}

.message-fabutton i:hover {
  color: #74b9ff;
  cursor: pointer;
}

.search-box {
  background: #fafafa;
  padding: 10px 13px;
}

.search-box .input-wrapper {
  background: #fff;
  border-radius: 40px;
}

.search-box .input-wrapper i {
  color: grey;
  margin-left: 7px;
  vertical-align: middle;
}

input {
  border: none;
  border-radius: 30px;
  width: 90%;
}

input::placeholder {
  background-color: #fff;
  color: #74b9ff;
  font-weight: 300;
  margin-left: 20px;
}

input:focus {
  outline: none;
}

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: 0.3s ease;
}

.friend-drawer--grey {
  background: #eee;
}

.friend-drawer .text {
  margin-left: 12px;
  width: 70%;
}

.friend-drawer .text h6 {
  margin-top: 6px;
  margin-bottom: 0;
}

.friend-drawer .text p {
  margin: 0;
}

.friend-drawer .time {
  color: grey;
}

.friend-drawer--onhover:hover {
  background: #74b9ff;
  cursor: pointer;
}

.friend-drawer--onhover:hover p,
.friend-drawer--onhover:hover h6,
.friend-drawer--onhover:hover .time {
  color: #fff !important;
}

hr {
  margin: 5px auto;
  width: 60%;
}

.chat-bubble {
  min-height: 80px;
  padding: 10px 14px;
  background: #eee;
  margin: 10px 30px;
  border-radius: 9px;
  position: relative;
  animation: fadeIn 1s ease-in;
}

.chat-bubble:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-top: -10px;
}

.chat-bubble--left:after {
  left: 0;
  border-right-color: #eee;
  border-left: 0;
  margin-left: -20px;
}

.chat-bubble--right:after {
  right: 0;
  border-left-color: #74b9ff;
  border-right: 0;
  margin-right: -20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.offset-md-9 .chat-bubble {
  background: #74b9ff;
  color: #fff;
}

.chat-box-tray {
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  margin-top: 19px;
  bottom: 0;
}

.chat-box-tray input {
  margin: 0 10px;
  padding: 6px 2px;
  background-color: #fff;
}

.chat-box-tray i {
  color: grey;
  font-size: 30px;
  vertical-align: middle;
}

.chat-box-tray i:last-of-type {
  margin-left: 25px;
}

.message-info-container {
  display: flex;
  width: 100%;
}

.message-right {
  float: left;
  width: 80%;
}

.message-left {
  float: left;
  margin: 0 0 25px;
  position: relative;
  width: 20%;
}

.clear-message {
  clear: both;
}

.message-no-chat-bubble {
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #e6e9ec;
}

.chat-panel {
  max-height: 500px;
  overflow-y: auto;
}